import React from "react"
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CookieConsent from '../components/cookie-consent';
import GoogleTag from '../components/google-tag';
import FacebookPixel from '../components/facebook-pixel';
import MicrosoftClarity from "../components/microsoft-clarity";

export default function Layout({children, pageContext}) {
  const languagePath = pageContext?.language ? `/${pageContext.language}` : '';

  return (
      <>
        <div className="bg-slate-100">
          <Navbar className="mx-auto max-w-7xl z-50" languagePath={languagePath}></Navbar>
        </div>
        {children}
        <div className="bg-slate-100">
          <Footer className="mx-auto max-w-7xl z-50" languagePath={languagePath}></Footer>
        </div>
        <CookieConsent/>
        <GoogleTag/>
        <FacebookPixel/>
        <MicrosoftClarity/>
      </>
  )
}
