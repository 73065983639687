import React, {useEffect, useState} from 'react';
import {Script} from 'gatsby';
import {useCookies} from 'react-cookie';
import { globalHistory } from '@reach/router';
declare var fbq: Function;

const FacebookPixel = () => {
  const [cookies] = useCookies();
  const [pixelConsent, setPixelConsent] = useState(true);
  const [pixelId, setPixelId] = useState(process.env.GATSBY_FACEBOOK_PIXEL_TRACKING_ID || '');
  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => setPixelConsent(cookies.cookie_consent?.performance || cookies.cookie_consent?.marketing),
      [cookies]
  );

  return (
      <>
        {
          pixelConsent ?
              <>
                <Script type="text/javascript">{`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${pixelId}');
                  fbq('track', 'PageView');`
                }
                </Script>
                <noscript>
                  <img height="1" width="1" style={{ display: 'none' }}
                       src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
                  />
                </noscript>
              </>
              :
              null
        }
      </>
  );
}

export default FacebookPixel;
