import React, {Fragment, useState} from 'react'
import {Dialog, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {Trans, useTranslation} from "react-i18next";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import LanguageSwitcher from "./language-switcher";
import {ChevronDownIcon} from "@heroicons/react/20/solid";

function Logo({languagePath}) {
  const {t} = useTranslation()
  return (
      <>
        <Link to={languagePath}>
          <span className="sr-only">
            <Trans>navbar.icon-alt</Trans>
          </span>
          <StaticImage
              className="h-8 w-8"
              src="../images/icon.png"
              alt={t("navbar.icon-alt")}
          />

          <span className="font-bold pl-0.5">Horarium.<span className="text-primary">ai</span></span>
        </Link>
      </>
  )
}

export default function Navbar({languagePath, ...props}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigation = [
    {name: 'navbar.features', href: `${languagePath}/features`, desktop: true},
    {name: 'navbar.pricing', href: `${languagePath}/pricing`, desktop: true},
    {name: 'common.blog', href: `${languagePath}/blog`, desktop: false},
    {name: 'common.help', href: `${languagePath}/help`, desktop: false},
    {name: 'common.testimonials', href: `${languagePath}/testimonials`, desktop: false},
    {name: 'common.contact-us', href: `${process.env.GATSBY_APP_URL}/topics/add`, desktop: false},
  ];

  return (
      <header {...props}>
        <nav className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex flex-shrink-0 items-center">
            <Logo languagePath={languagePath}/>
          </div>
          <div className="flex lg:hidden">
            <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-700"
                onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12 lg:px-12 items-center">
            {navigation
                .filter(item => item.desktop)
                .map((item) => (
                <Link key={item.name} to={item.href}
                      className="text-sm font-semibold leading-6 text-slate-900">
                  <Trans>{item.name}</Trans>
                </Link>
            ))}
            <div>
              <Menu as="div" className="relative inline-block text-center">
                <div>
                  <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-slate-900">
                    <Trans>common.resources</Trans>
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-slate-400" />
                  </MenuButton>
                </div>

                <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem>
                      <Link to={`${languagePath}/blog`}
                            className="block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100 data-[focus]:text-slate-900">
                        <Trans>common.blog</Trans>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to={`${languagePath}/help`}
                            className="block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100 data-[focus]:text-slate-900">
                        <Trans>common.help</Trans>
                      </Link>
                    </MenuItem>
                  </div>
                  <div className="py-1">
                    <MenuItem>
                      <Link to={`${languagePath}/testimonials`}
                            className="block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100 data-[focus]:text-slate-900">
                        <Trans>common.testimonials</Trans>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to={`${process.env.GATSBY_APP_URL}/topics/add`}
                            className="block px-4 py-2 text-sm text-slate-700 data-[focus]:bg-slate-100 data-[focus]:text-slate-900">
                        <Trans>common.contact-us</Trans>
                      </Link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
            </div>
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
            <div className="h-full w-40 pr-6">
              <LanguageSwitcher></LanguageSwitcher>
            </div>
            <a href={`${process.env.GATSBY_APP_URL}/login`}
               className="text-sm font-semibold leading-6 text-slate-900">
              <Trans>login</Trans> <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50"/>
          <Dialog.Panel
              className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-slate-900/10">
            <div className="flex items-center justify-between">
              <Link to="${languagePath}" className="-m-1.5 p-1.5">
                <Logo languagePath={languagePath}/>
              </Link>
              <button type="button"
                      className="-m-2.5 rounded-md p-2.5 text-slate-700"
                      onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-slate-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                      <Link key={item.name} to={item.href}
                            onClick={() => setMobileMenuOpen(false)}
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-900 hover:bg-slate-50">
                        <Trans>{item.name}</Trans>
                      </Link>
                  ))}
                </div>
                <div className="py-6 w-full">
                  <LanguageSwitcher></LanguageSwitcher>
                </div>
                <div className="py-6">
                  <a href={`${process.env.GATSBY_APP_URL}/login`}
                     className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-slate-900 hover:bg-slate-50">
                    <Trans>login</Trans> <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
  )
}
