import React, {useEffect, useState} from 'react';
import {Script} from 'gatsby';
import {useCookies} from 'react-cookie';
import { globalHistory } from '@reach/router';
declare var gtag: (key: string, name: string, object?: any) => void;

const GoogleTag = () => {
  const [cookies] = useCookies();
  const [gtagConsent, setGtagConsent] = useState(true);
  const [gtagId, setGtagId] = useState(process.env.GATSBY_GOOGLE_TRACKING_ID || '');
  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => setGtagConsent(cookies.cookie_consent?.performance || cookies.cookie_consent?.marketing),
      [cookies]
  );

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      const currLocation = window.location.toString();
      if (action === 'PUSH' && currentLocation !== currLocation && gtagConsent) {
        setCurrentLocation(currLocation);
        gtag('config', gtagId, { send_page_view: false });
        gtag('event', 'page_view',
            { title: currLocation, page: currLocation, page_title: currLocation, page_path: currLocation });
      }
    });
  }, [currentLocation, gtagConsent]);

  return (
      <>
        {
          gtagConsent ?
              <>
                <Script type="text/javascript" src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}></Script>
                <Script type="text/javascript">{`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', \'${gtagId}\', { send_page_view: true });
                  gtag('consent', 'update', {
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted'
                  });`
                }
                </Script>
              </>
              :
              null
        }
      </>
  );
}

export default GoogleTag;
