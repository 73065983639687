import {NodeInput, WrapPageElementBrowserArgs} from "gatsby";
import i18next, {i18n as I18n, Resource, ResourceKey} from "i18next";
import {I18nextProvider} from "react-i18next";
import React from "react";


interface LocaleNodeInput extends NodeInput {
  language: string;
  ns: string;
  data: string;
  fileAbsolutePath: string;
}

interface LocaleNode extends LocaleNodeInput {
  parent: string;
  children: string[];
  internal: NodeInput['internal'] & {
    owner: string;
  };
}

export type PageContext = {
  path?: string;
  language: string;
  i18n: I18NextContext;
};

export type I18NextContext = {
  language: string;
  routed: boolean;
  languages: string[];
  defaultLanguage: string;
  generateDefaultLanguagePage: boolean;
  originalPath: string;
  path: string;
  siteUrl?: string;
};


export const wpe = (
    {element, props}: WrapPageElementBrowserArgs<any, PageContext>,
    {
      i18nextOptions = {},
      redirect = true,
      generateDefaultLanguagePage = false,
      siteUrl,
      localeJsonNodeName = 'locales',
      fallbackLanguage,
      trailingSlash
    }: any
) => {
  if (!props) return;
  const {data, pageContext, location} = props;
  const {routed, language, languages, originalPath, defaultLanguage, path} = pageContext.i18n;
  const localeNodes: Array<{node: LocaleNode}> = data?.[localeJsonNodeName]?.edges || [];
  const namespaces = localeNodes.map(({node}) => node.ns);

  let defaultNS = i18nextOptions.defaultNS?.toString() || 'translation';
  defaultNS = namespaces.find((ns) => ns !== defaultNS) || defaultNS;
  const fallbackNS = namespaces.filter((ns) => ns !== defaultNS);

  const resources: Resource = localeNodes.reduce<Resource>((res: Resource, {node}) => {
    const parsedData: ResourceKey =
        typeof node.data === 'object' ? node.data : JSON.parse(node.data);

    if (!(node.language in res)) res[node.language] = {};

    res[node.language][node.ns || defaultNS] = parsedData;

    return res;
  }, {});

  const i18n = i18next.createInstance();

  i18n.init({
    resources,
    defaultNS,
    fallbackNS,
    fallbackLng: 'en',
    debug: false,
    detection: detectionOptions,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }

  const context = {
    routed,
    language,
    languages,
    originalPath,
    defaultLanguage,
    generateDefaultLanguagePage,
    siteUrl,
    path
  };

  return withI18next(i18n, context)(element);
};

const I18nextContext = React.createContext<I18NextContext>({
  language: 'en',
  languages: ['en'],
  routed: false,
  defaultLanguage: 'en',
  generateDefaultLanguagePage: false,
  originalPath: '/',
  path: '/'
});

const withI18next = (i18n: I18n, context: I18NextContext) => (children: any) => {
  return (
      <I18nextProvider i18n={i18n}>
        <I18nextContext.Provider value={context}>{children}</I18nextContext.Provider>
      </I18nextProvider>
  );
};

const detectionOptions = {
  order: ['path'],
  lookupFromPathIndex: 0
}