import React, {Fragment, useEffect, useState} from 'react'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {navigate} from "gatsby";
import {useCookies} from "react-cookie";
import {useLocation} from "@reach/router"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const languages: [{id: number, lang: string, name: string, locale: string}] = [
  {
    id: 1,
    lang: 'en',
    name: 'English',
    locale: 'en-UK'
  },
  {
    id: 2,
    lang: 'de',
    name: 'Deutsch',
    locale: 'de-DE'
  },
  {
    id: 3,
    lang: 'es',
    name: 'Español',
    locale: 'es-ES'
  },
  {
    id: 4,
    lang: 'it',
    name: 'Italiano',
    locale: 'it-IT'
  },
  {
    id: 5,
    lang: 'fr',
    name: 'Français',
    locale: 'fr-FR'
  },
  {
    id: 6,
    lang: 'pl',
    name: 'Polski',
    locale: 'pl-PL'
  },
  {
    id: 7,
    lang: 'tr',
    name: 'Türkçe',
    locale: 'tr-TR'
  },
  {
    id: 8,
    lang: 'nl',
    name: 'Netherlands',
    locale: 'nl-NL'
  },
  {
    id: 9,
    lang: 'pt',
    name: 'Português',
    locale: 'pt-PT'
  },
  {
    id: 10,
    lang: 'ro',
    name: 'Română',
    locale: 'ro_RO'
  }
];

function getLanguageFromUrl(pathname: string) {
  const [, language] = pathname.split('/');
  return languages.filter(l => l.lang === language)?.[0] || languages.filter(l => l.lang === 'en')?.[0];
}

const LanguageSwitcher = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['locale']);
  const location = useLocation();
  const [selected, setSelected] = useState(cookies.locale || getLanguageFromUrl(location.pathname));

  useEffect(() => {
    if (selected) {
      if (props.type === 'help') {
        setCookie('locale', JSON.stringify(selected), {domain: '.generator-orare.ro', path: '/'});
      } else {
        setCookie('locale', JSON.stringify(selected), {domain: '.horarium.ai', path: '/'});
      }

      const [, fistPath, ...remainingPath] = location.pathname.split('/');

      if (languages.filter(value => value.lang === fistPath).length > 0) {
        navigate(`/${selected.lang}/${remainingPath.join('/')}${location.search}`);
      } else if(fistPath) {
        navigate(`/${selected.lang}/${[fistPath, ...remainingPath].join('/')}${location.search}`);
      } else {
        navigate(`/${selected.lang}${location.search}`);
      }
    }
  }, [selected]);

  return (
      <Listbox value={selected} onChange={language => setSelected(language)}>
        {({ open }) => (
            <>
              <div className="relative">
                <ListboxButton
                    className="relative h-full w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:text-sm sm:leading-6">
                  <span className="flex items-center">
                    {/*<img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full"/>*/}
                    <span className="ml-3 block truncate">{selected?.name}</span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-slate-400" aria-hidden="true"/>
                  </span>
                </ListboxButton>

                <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {languages
                        .sort((lang1, lang2) => lang1.name.localeCompare(lang2.name))
                        .map((language) => (
                        <ListboxOption
                            key={language.id}
                            className={({ focus }) =>
                                classNames(
                                    focus ? 'bg-primary-600 text-white' : '',
                                    !focus ? 'text-slate-900' : '',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                            }
                            value={language}>
                          {({ selected, focus }) => (
                              <>
                                <div className="flex items-center">
                                  {/*<img src={person.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />*/}
                                  <span
                                      className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                  >
                            {language.name}
                          </span>
                                </div>

                                {selected ? (
                                    <span className={classNames(
                                            focus ? 'text-white' : 'text-primary-700',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                                ) : null}
                              </>
                          )}
                        </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Transition>
              </div>
            </>
        )}
      </Listbox>
  )
};

export default LanguageSwitcher
