import React, {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import CookieSvg from '../../assets/cookie.svg';
import {Trans} from 'react-i18next';

const CookieConsent = (props: any) => {
  const [consent, setConsent] =
      React.useState({necessary: true, performance: true, functional: false, marketing: false});

  const [showModal, setShowModal] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [cookies, setCookie] = useCookies();
  const cookieCategories = ['necessary', 'performance', 'functional', 'marketing'];

  useEffect(() => setShowModal(!cookies.cookie_consent), [cookies]);

  const saveCurrentCookieSettings = () => {
    if (props.type === 'help') {
      setCookie('cookie_consent', JSON.stringify(consent), {domain: '.generator-orare.ro', path: '/'});
    } else {
      setCookie('cookie_consent', JSON.stringify(consent), {domain: '.horarium.ai', path: '/'});
    }
    setShowModal(false);
  };

  const acceptAllCookies = () => {
    const consentForAll = {necessary: true, functional: true, marketing: true, performance: true};
    if (props.type === 'help') {
      setCookie('cookie_consent', JSON.stringify(consentForAll), {domain: '.generator-orare.ro', path: '/'});
    } else {
      setCookie('cookie_consent', JSON.stringify(consentForAll), {domain: '.horarium.ai', path: '/'});
    }
    setShowModal(false);
  };

  return (
      showModal ?
        <div
            className="fixed max-w-2xl min-w-[80vw] lg:min-w-0 p-4 border md:gap-x-4 left-[50%] -translate-x-[50%] bottom-16 bg-gray-900 md:flex md:items-center border-gray-700 rounded-2xl z-50">
          <div className="flex items-center gap-x-4">
            <span className="inline-flex p-2 text-gray-500 rounded-lg shrink-0 bg-gray-800">
                <CookieSvg/>
            </span>

            {showSettings ?
                <div className="flex flex-col gap-2 text-gray-300 max-h-[70vh] overflow-auto">
                  {cookieCategories.map(category => (
                      <div className="flex flex-col">
                        <div className="flex flex-row gap-2">
                          <input type="checkbox"
                                 disabled={category === cookieCategories[0]}
                                 checked={consent[category]}
                                 onChange={val => setConsent({ ...consent, [category] : !consent[category] })}/>
                          <b><Trans>{`cookies.${category}.name`}</Trans></b>
                        </div>
                        <div className="text-xs">
                          <Trans>{`cookies.${category}.details`}</Trans>
                        </div>
                      </div>
                  ))}
                </div>
                :
                <p className="text-sm text-gray-300"><Trans>cookies.consent-title</Trans>
                  <a href={`${process.env.GATSBY_APP_URL}/legal/cookies`} className="text-blue-500 hover:underline">
                    <Trans>cookies.read-policy</Trans>
                  </a>.
                </p>
            }
          </div>

          <div className="ml-4 flex items-center mt-6 gap-x-4 shrink-0 lg:mt-0">
            <button onClick={() => showSettings ? saveCurrentCookieSettings() : setShowSettings(true)}
                    className="w-1/2 text-xs underline transition-colors duration-300 md:w-auto text-white hover:text-gray-400 focus:outline-none">
              {showSettings ? <Trans>cookies.save-settings</Trans> : <Trans>cookies.settings</Trans>}
            </button>

            <button onClick={acceptAllCookies}
                className="text-xs w-1/2 md:w-auto font-medium bg-primary rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
              <Trans>cookies.accept</Trans>
            </button>
          </div>
        </div> : null
  )
}

export default CookieConsent;
